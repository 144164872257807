import React from 'react';
import {
    createFromIconfontCN
} from '@ant-design/icons';

export default React;
export { useState, useEffect, useRef, useImperativeHandle, forwardRef, useMemo } from 'react';
export { Link, useNavigate, useLocation } from 'react-router-dom';
export { default as Func } from './Func';
export { get, post } from './fetch';
// export { default as store } from '@/store'
// export {default as List} from './List';
// export {default as Ope} from './Ope';
// export {default as Search} from './Search';

// 组件
export { default as MsgCaptcha } from './components/MsgCaptcha';
export { default as MyBreadcrumb } from './components/Breadcrumb';
export { default as Area } from './components/Area';
export { default as Ope } from './components/Ope';
export { default as List } from './components/List';
export { default as Search } from './components/Search';
export { default as Delete } from './components/Delete';
export { default as MyTree } from './components/Tree';
export { default as DebounceSelect } from './components/DebounceSelect';
export { default as MyUpload } from './components/Upload';
export { default as Detail } from './components/Detail';
export { default as Export } from './components/Export';
export { default as Import } from './components/Import';
export { default as Wangeditor } from './components/Wangeditor';
export { default as MyTag } from './components/Tag';

// JS
export { default as qqmap } from './assets/js/qqmap';

// 配置
var config = Object.assign(require('@/config'), require(`@/config/${process.env.NODE_ENV}`))

// 常量
var constant = Object.assign(require('@/common/constant'), require('@/constant'))

// 阿里字体
var MyIcon = createFromIconfontCN({
    scriptUrl: config.ico.iconfont,
});

export { config, constant, MyIcon }