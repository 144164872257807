import { config } from '@/common';
import { message } from 'antd';

function doUrl(url: string) {
    let arr = url.split('/')
    let prefix = config.api.url.default
    if (config.api.url[arr[1]] !== undefined) {
        prefix = config.api.url[arr[1]]
        url = url.substring(arr[1].length + 1, url.length)
    }
    return new URL(prefix + url)
}

function doAuthority(data: any) {
    if (data.code === 2) {
        message.error(data.msg)
        // 清除本地会话信息
        message.error("身份失效，请重新登陆")
        localStorage.removeItem('member')
        localStorage.removeItem('module')
        window.location.href = "/login"
    }
}

const setToken = (headers: any) => {
    try {
        let member = JSON.parse(localStorage.getItem('member') || '{}')
        if (member.token) headers['Token-Member'] = member.token
    } catch (error) {
    }
    return headers
    // try {
    //     let member = JSON.parse(localStorage.getItem('member') || '{}')
    //     if (member.token) {
    //         headers['Token-Member'] = member.token
    //     } else {
    //         let token = (new URL(window.location.href)).searchParams.get('_token')
    //         if (token) headers['Token-Member'] = token
    //     }
    // } catch (error) {
    // }
}

async function get(url: string, data?: any, response_type?: string) {
    let url_obj = doUrl(url)
    if (data) Object.keys(data).forEach(key => { if (data[key] !== undefined) url_obj.searchParams.set(key, data[key]) })
    let headers = {
        'Mos-Platform':config.app.id
    }
    // 设置token
    headers = setToken(headers)
    // try {
    //     let member = JSON.parse(localStorage.getItem('member') || '{}')
    //     if (member.token) headers['Token-Member'] = member.token
    // } catch (error) {

    // }
    if (response_type) {
        return await fetch(url_obj.href, {
            method: "GET",
            mode: 'cors',
            headers
        })
            .then(res => {
                return {
                    headers: res.headers,
                    blob: res.blob()
                }
            })
            .catch(error => {
                message.error(error)
            });
    } else {
        return fetch(url_obj.href, {
            method: "GET",
            mode: 'cors',
            headers
        })
            .then(res => res.json())
            .then(data => {
                // 权限处理
                doAuthority(data)
                if (data.code) {
                    message.error(data.msg)
                }
                return data
            })
    }
}

async function post(url: string, data?: any, show_msg = true) {
    let url_obj = doUrl(url)
    let headers = {
        'Mos-Platform':config.app.id,
        'Content-Type': 'application/json'
    }
    // 设置token
    headers = setToken(headers)
    // try {
    //     let member = JSON.parse(localStorage.getItem('member') || '{}')
    //     if (member.token) headers['Token-Member'] = member.token
    // } catch (error) {

    // }
    return fetch(url_obj.href, {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(data),
        headers,
    })
        .then(res => res.json())
        .then(data => {
            // 权限处理
            doAuthority(data)
            // 提示
            if (show_msg) {
                if (data.code) {
                    message.error(data.msg)
                } else {
                    message.success(data.msg)
                }
            }
            return data
        })
}

export { get, post }