import { config, useState, useEffect, Func } from '@/common'
import { Upload, Button } from 'antd';
import '../assets/style/upload.scss';

import {
    PlusOutlined,
    UploadOutlined
} from '@ant-design/icons';

function Main(props: any) {
    const [file_list, setFileList] = useState<any>([]);
    const [default_status, setDefaultStatus] = useState(true)
    let member = {
        token: ''
    }
    try {
        member = JSON.parse(localStorage.getItem('member') || '{}')
    } catch (error) {

    }

    // 默认值
    let listType = 'picture-card'
    let accept = 'image/png, image/jpeg, image/gif, image/jpg, image/svg+xml'

    const onChange = (info: any) => {
        setDefaultStatus(false)
        let new_file_list = info.fileList
        let vals: any = []
        new_file_list.forEach((e: any) => {
            if (e.response && !e.response.code) {
                if (props.type === 'file') {
                    vals.push({
                        'name': e.name,
                        'size': e.size,
                        'path': e.response.data
                    })
                } else {
                    vals.push(e.response.data)
                }
                e.url = Func.getRealUrl(e.response.data)
            }
        });
        if (vals.length === new_file_list.length) props.onChange(props.multiple ? vals : vals[0])
        setFileList(new_file_list)
    }

    let UploadButton =
        <div>
            <PlusOutlined />
            <div>{props.btnText || '上传图片'}</div>
        </div>
    if (props.type == 'file') {
        UploadButton = <Button icon={<UploadOutlined />}>{props.btnText || '上传文件'}</Button>
        listType = 'text'
        accept = 'application/msword, application/pdf, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }

    useEffect(() => {
        if (props.value && default_status) {
            let data = props.multiple ? props.value : [props.value]
            let list: any[] = []
            data.forEach((e: any) => {
                if (props.type == 'file') {
                    list.push({
                        name: e.name,
                        url: Func.getRealUrl(e.path),
                        response: {
                            data: e.path
                        }
                    })
                } else {
                    list.push({
                        url: Func.getRealUrl(e),
                        response: {
                            data: e
                        }
                    })
                }
            });
            setFileList(list)
        }
    }, [props])

    return (
        <Upload
            listType={listType}
            accept={accept}
            className='upload'
            headers={{
                'Token-Member': member.token
            }}
            action={config.api.url.default + '/resource/upload'}
            fileList={file_list}
            {...props}
            onChange={onChange}
        >
            {!props.multiple && file_list.length > 0 ? null : UploadButton}
        </Upload>
    )
}
export default Main