import { useState, get, useEffect, constant } from '@/common'
import { useParams } from 'react-router-dom';

function Main() {
    const params: any = useParams()
    const [html, setHtml] = useState('');

    const getConfig = () => {
        document.title = constant.config[params.key]
        get('/opens/config').then(data => {
            data = data.data
            if (data[params.key]) setHtml(data[params.key])
        })
    }
    useEffect(() => {
        getConfig()
        // 设置viewport
        document.querySelector('meta[name="viewport"]')?.setAttribute("content","width=device-width,initial-scale=1")
    }, [])
    return (
        <div dangerouslySetInnerHTML={{ __html: html }} className="webview"></div>
    )
}

export default Main